import React, { useState, useEffect } from 'react';
import { Typography, Box, Paper, TextField, Button, Grid, CssBaseline, FormControl, FormLabel } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

// Create RTL cache
const cacheRtl = createCache({
  key: 'muirtl',
  prepend: true,
});

// Create RTL theme
const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontFamily: 'Cairo, sans-serif',
  },
});

const PermissionRequestForm = () => {
  const [permissionData, setPermissionData] = useState({
    employeeName: '',
    department: '',
    permissionType: '',
    permissionReason: '',
    startTime: '',
    endTime: '',
  });
  const [username, setUsername] = useState('');
  const [totalPermissionHours, setTotalPermissionHours] = useState(0);
  const [pendingPermissionHours, setPendingPermissionHours] = useState(0);

  const location = useLocation();  // Use location hook outside of useEffect

  useEffect(() => {
    const usernameFromLocation = location.state?.username; // Use variable instead of direct access
    setUsername(usernameFromLocation);

    if (usernameFromLocation) {
      fetchUserPermissions(usernameFromLocation);
    }
  }, [location]);  // Correct dependency array

  const fetchUserPermissions = async (username) => {
    try {
      const response = await axios.get('https://backedndforelbatt.elbatt.com/getPermissions.php');
      // Make sure to check the structure of response.data
      if (!response.data || !Array.isArray(response.data.data)) {
        console.error('Data fetched is not an array:', response.data);
        throw new Error('Data fetched is not an array');
      }
      const permissions = response.data.data.filter(permission => permission.username === username);
  
      let acceptedHours = 0;
      let pendingHours = 0;
  
      permissions.forEach(permission => {
        const startTime = new Date(`1970-01-01T${permission.start_time}Z`);
        const endTime = new Date(`1970-01-01T${permission.end_time}Z`);
        const hours = (endTime - startTime) / (1000 * 60 * 60); // Calculate difference in hours
  
        if (permission.is_accepted === 1) {
          acceptedHours += hours;
        } else {
          pendingHours += hours;
        }
      });
  
      setTotalPermissionHours(acceptedHours);
      setPendingPermissionHours(pendingHours);

      // Return the filtered permissions for further use
      return permissions;
    } catch (error) {
      console.error('Error fetching permissions:', error);
      if (error.response) {
        // Handle errors from the server side
        console.error('Server response error:', error.response.data);
      } else if (error.request) {
        // Handle errors from the request itself
        console.error('Request error:', error.request);
      } else {
        // Handle other errors
        console.error('General error:', error.message);
      }
    }
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPermissionData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  
    const startTime = new Date(`1970-01-01T${permissionData.startTime}Z`);
    const endTime = new Date(`1970-01-01T${permissionData.endTime}Z`);
    const requestedHours = (endTime - startTime) / (1000 * 60 * 60);
  
    // منع الأوقات السالبة
    if (requestedHours <= 0) {
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: 'وقت الانتهاء يجب أن يكون بعد وقت البدء.',
      });
      return;
    }
  
    axios
      .get('https://backedndforelbatt.elbatt.com/getPermissions.php')
      .then((response) => {
        if (!response.data || !Array.isArray(response.data.data)) {
          throw new Error('البيانات المسترجعة غير صحيحة.');
        }
  
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
  
        const userPermissionsThisMonth = response.data.data.filter((permission) => {
          const createdAt = new Date(permission.created_at);
          return (
            permission.username === username &&
            permission.is_accepted ===1 && 
            createdAt.getMonth() === currentMonth &&
            createdAt.getFullYear() === currentYear
          );
        });
  
        // التحقق من عدد الطلبات
        if (userPermissionsThisMonth.length >= 2) {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: 'لا يمكن تسجيل أكثر من طلبين في الشهر.',
          });
          return;
        }

        // تحقق من ساعات الطلب الحالي (إذن واحد لا يزيد عن 2 ساعة)
        if (requestedHours > 2) {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: 'لا يمكن طلب أكثر من ساعتين في الإذن الواحد.',
          });
          return;
        }
  
        // التحقق من الساعات الإجمالية
        const totalRequestedHours = totalPermissionHours + pendingPermissionHours + requestedHours;
  
        if (totalRequestedHours > 4) {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: `لقد طلبت بالفعل ${totalPermissionHours.toFixed(2)} ساعات وتم طلب ${pendingPermissionHours.toFixed(2)} ساعات في انتظار الموافقة. الحد الأقصى الإجمالي هو 4 ساعات.`,
          });
          return;
        }
  
        const formData = { ...permissionData, username };
  
        axios
          .post('https://backedndforelbatt.elbatt.com/postPermissions.php', formData)
          .then((response) => {

            // إعادة تعيين النموذج
            setPermissionData({
              employeeName: '',
              department: '',
              permissionType: '',
              permissionReason: '',
              startTime: '',
              endTime: '',
            });

            // تحديث البيانات الحالية دون الحاجة إلى جلب جديد
            const newPermission = {
              ...formData,
              start_time: formData.startTime,
              end_time: formData.endTime,
              is_accepted: 0, // إذا كانت الموافقة على الإذن تبدأ كـ "معلق"
            };

            Swal.fire({
              icon: 'success',
              title: 'تم إرسال الطلب بنجاح',
              text: 'تم تقديم طلب الإذن بنجاح.',
            });
            // إضافة البيانات إلى الحالة الحالية لتحديث الواجهة بدون تحديث الصفحة
            fetchUserPermissions(username);

            // console.log('Form submitted successfully:', response.data);
          })
          .catch((error) => {
            let errorMessage = 'حدث خطأ ما. حاول مرة أخرى.';
            if (error.response) {
              errorMessage = error.response.data.message || errorMessage;
              console.error('Error response:', error.response.data);
            } else if (error.request) {
              console.error('Error request:', error.request);
            } else {
              console.error('Error message:', error.message);
            }
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text: errorMessage,
            });
          });
      })
      .catch((error) => {
        console.error('Error fetching permissions:', error);
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: 'حدث خطأ أثناء التحقق من البيانات. حاول مرة أخرى.',
        });
      });
  };
  
  useEffect(() => {
    if (username) {
      const fetchData = async () => {
        const permissions = await fetchUserPermissions(username);
        console.log('Fetched permissions:', permissions);
      };
  
      fetchData();
    }
  }, [username]);


  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box p={2} style={{ direction: 'rtl', fontFamily: 'Cairo, sans-serif', height: '100%' }}>
          <Typography variant="h5" gutterBottom>إذن تأخير / إنصراف</Typography>
          <Paper style={{ padding: '16px', height: '100%' }}>
            <form onSubmit={handleSubmit} style={{ height: '100%' }}>
              <Grid container spacing={2} style={{ height: '100%' }}>
                <Grid item xs={12}>
                  <TextField
                    label="اسم الموظف"
                    name="employeeName"
                    fullWidth
                    value={permissionData.employeeName}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="القسم"
                    name="department"
                    fullWidth
                    value={permissionData.department}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend" style={{ textAlign: 'right' }}>نوع الإذن</FormLabel>
                    <TextField
                      name="permissionType"
                      fullWidth
                      value={permissionData.permissionType}
                      onChange={handleChange}
                      inputProps={{ style: { textAlign: 'right' } }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="سبب التأخير / الانصراف"
                    name="permissionReason"
                    fullWidth
                    value={permissionData.permissionReason}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="وقت البدء"
                    name="startTime"
                    type="time"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={permissionData.startTime}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="وقت الانتهاء"
                    name="endTime"
                    type="time"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={permissionData.endTime}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    إرسال
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Box>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default PermissionRequestForm;
