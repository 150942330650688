import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Badge,
  Typography,
  CssBaseline,
  Box,
  Avatar,
  IconButton,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import {
  Home as HomeIcon,
  Person as PersonIcon,
  Mail as MailIcon,
  Notifications as NotificationsIcon,
  ExpandLess,
  ExpandMore,
  Assignment as AssignmentIcon,
  Settings as SettingsIcon,
  ExitToApp as ExitToAppIcon,
  TableChart as TableChartIcon,
  ViewList as ViewListIcon,
  ReportProblem as ComplaintIcon,
  Event as EventIcon,
  ThumbUp as ThumbUpIcon,
  Comment as CommentIcon,
} from "@mui/icons-material";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import axios from "axios";
import image from "../../images/logooo.png";

import Dashboard from "./HomeComponent";
import Settings from "./Setting";
import TaskView from "./TaskView";
import TaskList from "./TasksList";
import Profile from "./userProfile";
import Complaints from "../../Components/ProplemsForUser/Proplems";
import Employees from "../../Components/EmployeesForUsers/EmployeesForUsers";
import PermissionsAndLeaves from "../../Components/PermissionsAndLeaves/PermissionsAndLeaves";
import Tickets from "../../Components/Tickets/Tickets";
import UserOfficialHolidays from "../../Components/UserOfficialHolidays/UserOfficialHolidays";
import TeamLeaderSkills from "../../Components/TeamLeaderSkills/TeamLeaderSkills";
import { Grid } from "@mui/material";
import MeetingTimes from "../../Components/MeetingTimes/MeetingTimes";

const drawerWidth = 240;

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#121212",
      paper: "#1f1f1f",
    },
    text: {
      primary: "#ffffff",
      secondary: "#cccccc",
    },
    success: {
      main: "#6fd943",
    },
  },
  typography: {
    fontFamily: "Cairo, sans-serif",
  },
});

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100vh",
  backgroundColor: theme.palette.background.default,
}));

const DrawerStyled = styled(Drawer)(({ theme }) => ({
  margin: "25px",
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    right: 16,
    left: "auto",
    textAlign: "right",
    fontFamily: "Cairo, sans-serif",
    backgroundColor: theme.palette.background.paper,
    border: "none",
  },
}));

const Content = styled("div")(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  fontFamily: "Cairo, sans-serif",
  backgroundColor: theme.palette.background.default,
}));

const Header = styled("header")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 16px",
  backgroundColor: "transparent",
  height: "64px",
  borderBottom: "none",
  fontFamily: "Cairo, sans-serif",
}));

const LogoContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "16px 0",
  borderBottom: "none",
}));

const MainContent = styled("main")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  fontFamily: "Cairo, sans-serif",
}));

const UserDashboardSidebar = () => {
  const [selectedComponent, setSelectedComponent] = useState("Dashboard");
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [meetingTimes, setMeetingTimes] = useState([]);
  const [posts, setPosts] = useState([]);
  const [newComment, setNewComment] = useState({});
  const [likesData, setLikesData] = useState([]);
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  // Additional state for notification count
  const [notificationCount, setNotificationCount] = useState(0);

  const navigate = useNavigate(); // Add this line inside your UserDashboardSidebar component

  useEffect(() => {
    // Assuming `user` contains the current user's username
    if (user && user.username) {
      // Function to fetch leave requests and permissions
      const fetchNotifications = async () => {
        try {
          const leavesResponse = await axios.get(
            "https://backedndforelbatt.elbatt.com/getleaveRequest.php"
          );
          const permissionsResponse = await axios.get(
            "https://backedndforelbatt.elbatt.com/getPermissions.php"
          );

          const leavesData = leavesResponse.data.data || [];
          const permissionsData = permissionsResponse.data.data || [];

          const currentDate = new Date().toISOString().split("T")[0]; // Format: 'YYYY-MM-DD'

          // Filter data for current user and current day, count all requests
          const leavesCount = leavesData.filter(
            (leave) =>
              leave.username === user.username &&
              leave.start_date === currentDate
          ).length;

          const permissionsCount = permissionsData.filter(
            (permission) =>
              permission.username === user.username &&
              permission.created_at.startsWith(currentDate) // Assuming `created_at` includes time
          ).length;

          setNotificationCount(leavesCount + permissionsCount);
        } catch (error) {
          console.error("Error fetching notification data:", error);
        }
      };

      fetchNotifications();
    }
  }, [user]); // Re-run when `user` changes

  const location = useLocation();

  useEffect(() => {
    const username = location.state?.username; // Access the username from the state with optional chaining

    if (username) {
      axios
        .get("https://backedndforelbatt.elbatt.com/getemployees.php")
        .then((response) => {
          const userData = response.data.data.find(
            (user) => user.username === username
          );
          setUser(userData);
        })
        .catch((error) => {
          console.error("Error fetching employee data:", error);
        });

      axios
        .get("https://backedndforelbatt.elbatt.com/get_meeting_times.php")
        .then((response) => {
          if (Array.isArray(response.data)) {
            setMeetingTimes(response.data);
          } else {
            console.error("Unexpected meeting times format:", response.data);
            setMeetingTimes([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching meeting times:", error);
          setMeetingTimes([]);
        });

      axios
        .get("https://backedndforelbatt.elbatt.com/addPost.php")
        .then((response) => {
          setPosts(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching posts:", error);
        });

      // Fetch likes data
      axios
        .get("https://backedndforelbatt.elbatt.com/getLikes.php")
        .then((response) => {
          if (response.data.success) {
            setLikesData(response.data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching likes data:", error);
        });
    }
  }, [location]);

  const handleNavigation = (component) => {
    setSelectedComponent(component);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("authTokenEmployee"); // Remove token from sessionStorage
    sessionStorage.removeItem("sessionStartTime"); // Optionally remove session start time if needed
    setIsSessionExpired(false); // Close the dialog
    navigate("/login"); // Navigate to the login page
  };

  useEffect(() => {
    const sessionStartTime = sessionStorage.getItem("sessionStartTime");
    if (!sessionStartTime) {
      // If sessionStartTime is not set, initialize it to the current time
      sessionStorage.setItem("sessionStartTime", Date.now());
    }

    const sessionDuration = 60 * 20000; // Adjust this value if needed (20,000 ms = 20 seconds for testing)

    const checkSessionExpiry = () => {
      const currentTime = Date.now();
      const startTime = parseInt(sessionStartTime, 10);

      if (currentTime - startTime > sessionDuration) {
        setIsSessionExpired(true); // Show the dialog when the session has expired
      }
    };

    const interval = setInterval(checkSessionExpiry, 1000); // Check every second

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []); // Empty dependency array means this effect runs once on mount

  const handleLikePost = (postId) => {
    if (!user || !user.username || !postId) {
      console.error("Missing required fields for liking the post", {
        postId,
        username: user?.username,
      });
      return;
    }

    const formData = new URLSearchParams();
    formData.append("postId", postId);
    formData.append("username", user.username);

    axios
      .post("https://backedndforelbatt.elbatt.com/likePost.php", formData)
      .then((response) => {
        if (response.data.success) {
          setPosts((prevPosts) =>
            prevPosts.map((post) => {
              if (post.id === postId) {
                return {
                  ...post,
                  hasLiked: true,
                  likes: parseInt(post.likes || 0) + 1,
                };
              }
              return post;
            })
          );
        } else {
          console.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error liking post:", error);
      });
  };

  const handleCommentChange = (event, postId) => {
    setNewComment((prevComments) => ({
      ...prevComments,
      [postId]: event.target.value,
    }));
  };

  const handleAddComment = (postId) => {
    const commentText = newComment[postId] || "";

    if (!user || !user.username || !postId || !commentText.trim()) {
      console.error("Missing required fields for adding a comment", {
        postId,
        username: user?.username,
        commentText,
      });
      return;
    }

    const formData = new URLSearchParams();
    formData.append("postId", postId);
    formData.append("username", user.username);
    formData.append("commentText", commentText);

    axios
      .post("https://backedndforelbatt.elbatt.com/addComment.php", formData)
      .then((response) => {
        if (response.data.success) {
          setPosts((prevPosts) =>
            prevPosts.map((post) =>
              post.id === postId
                ? {
                    ...post,
                    comments: [
                      ...post.comments,
                      {
                        id: new Date().getTime(),
                        comment_text: commentText,
                        username: user.username,
                        created_at: new Date().toISOString(),
                      },
                    ],
                  }
                : post
            )
          );
          setNewComment((prevComments) => ({
            ...prevComments,
            [postId]: "", // Clear comment input after adding
          }));
        } else {
          console.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error adding comment:", error);
      });
  };

  const getLikesForPost = (postId) => {
    return likesData.filter((like) => like.post_id === String(postId)).length;
  };

  const userHasLikedPost = (postId) => {
    return likesData.some(
      (like) =>
        like.post_id === String(postId) && like.username === user?.username
    );
  };

  const renderComponent = () => {
    switch (selectedComponent) {
      case "Dashboard":
        return <Profile />;
      case "Profile":
        return <Dashboard />;
      case "TaskList":
        return <TaskList />;
      case "TaskView":
        return <TaskView />;
      case "Employees":
        return <Employees />;
      case "PermissionsAndLeaves":
        return <PermissionsAndLeaves />;
      case "Tickets":
        return <Tickets />;
      case "Complaints":
        return <Complaints user={user} />;
      case "OfficialHolidays":
        return <UserOfficialHolidays />;
      case "Settings":
        return <Settings user={user} />;
      case "TeamLeaderSkills":
        return <TeamLeaderSkills user={user} />;
      case "ProfessionalPosts":
        return (
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={8}>
              <Typography
                variant="h4"
                style={{
                  color: "#6fd943", // Consistent success color
                  marginBottom: "16px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                المنشورات المهنية
              </Typography>
              {posts.map((post) => (
                <Paper
                  key={post.id}
                  elevation={3}
                  style={{
                    padding: "24px",
                    marginBottom: "24px",
                    borderRadius: "12px",
                    backgroundColor: "black", // Dark background
                    color: "#ffffff", // White text
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {post.post_image_url && (
                    <Box
                      style={{
                        width: "100%",
                        height: "400px",
                        overflow: "hidden",
                        borderRadius: "12px",
                        marginBottom: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "black",
                      }}
                    >
                      <img
                        src={`https://backedndforelbatt.elbatt.com/${post.post_image_url}`}
                        alt="Post"
                        style={{
                          width: "auto",
                          height: "100%",
                          objectFit: "contain",
                          transition: "transform 0.3s ease-in-out",
                        }}
                      />
                    </Box>
                  )}
                  <Typography
                    variant="h5"
                    style={{
                      marginBottom: "12px",
                      fontWeight: "bold",
                      color: "#ffffff",
                    }}
                  >
                    {post.post_title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginBottom: "16px", color: "#cccccc" }} // Light gray for timestamps
                  >
                    {new Date(post.created_at).toLocaleString("en-US", {
                      hour12: true,
                    })}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      marginBottom: "16px",
                      lineHeight: "1.6",
                      color: "#cccccc", // Light gray text for content
                      fontSize: "1rem",
                    }}
                  >
                    {post.post_content}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      <IconButton
                        onClick={() => handleLikePost(post.id)}
                        style={{
                          color: userHasLikedPost(post.id) ? "gray" : "#6fd943",
                        }} // Disabled button color
                        disabled={userHasLikedPost(post.id)}
                      >
                        <ThumbUpIcon />
                      </IconButton>
                      <Typography
                        style={{ marginRight: "8px", color: "#ffffff" }}
                      >
                        {getLikesForPost(post.id)}
                      </Typography>
                      <IconButton style={{ color: "#6fd943" }}>
                        <CommentIcon />
                      </IconButton>
                    </Box>
                    <Box display="flex" alignItems="center" flexGrow={1}>
                      <TextField
                        value={newComment[post.id] || ""}
                        onChange={(event) =>
                          handleCommentChange(event, post.id)
                        }
                        variant="outlined"
                        size="small"
                        placeholder="Add a comment..."
                        style={{
                          flexGrow: 1,
                          marginRight: "8px",
                          backgroundColor: "#333333", // Dark input background
                          color: "#ffffff", // White input text
                          borderRadius: "8px",
                        }}
                        InputProps={{
                          style: { color: "#ffffff" }, // Ensuring text in input field is white
                        }}
                      />
                      <Button
                        onClick={() => handleAddComment(post.id)}
                        variant="contained"
                        color="primary"
                        style={{
                          backgroundColor: "#6fd943", // Primary success button
                          marginLeft: "8px",
                        }}
                      >
                        Comment
                      </Button>
                    </Box>
                  </Box>
                  <Box mt={2}>
                    {post.comments.map((comment, index) => (
                      <Paper
                        key={index}
                        style={{
                          padding: "12px",
                          marginTop: "8px",
                          backgroundColor: "#333333", // Dark background for comments
                          borderRadius: "8px",
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{ color: "#ffffff" }}
                        >
                          {comment.comment_text}
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ color: "#cccccc" }}
                        >
                          - {comment.username} on{" "}
                          {new Date(comment.created_at).toLocaleString()}
                        </Typography>
                      </Paper>
                    ))}
                  </Box>
                </Paper>
              ))}
            </Grid>
          </Grid>
        );
      default:
        return <Dashboard />;
    }
  };

  const avatarSrc =
    user && user.photo
      ? `https://backedndforelbatt.elbatt.com/${user.photo}`
      : "/path/to/avatar.jpg";

  return (
    <ThemeProvider theme={darkTheme}>
      <Dialog open={isSessionExpired} onClose={handleLogout}>
        <DialogTitle>Session Expired</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your session has expired. Please click OK to log in again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <CssBaseline />
      <Root>
        <Content>
          <Header style={{ direction: "rtl" }}>
            <Box display="flex" alignItems="center">
              <Avatar alt="User Avatar" src={avatarSrc} />
              <Typography
                variant="h6"
                noWrap
                style={{ fontFamily: "Cairo, sans-serif", marginRight: "8px" }}
              >
                أهلا {user ? user.name : "User"}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <IconButton color="inherit">
                <MailIcon style={{ color: "#6fd943" }} />
              </IconButton>
              <IconButton color="inherit">
                <NotificationsIcon style={{ color: "#6fd943" }} />
              </IconButton>
            </Box>
          </Header>
          <MainContent>{renderComponent()}</MainContent>
        </Content>
        <DrawerStyled variant="permanent" anchor="right">
          <LogoContainer style={{ backgroundColor: "#232627" }}>
            <img
              src={image}
              alt="Logo"
              style={{ height: "40px", backgroundColor: "#232627" }}
            />
          </LogoContainer>
          <List style={{ backgroundColor: "black", height: "100%" }}>
            <ListItem
              button
              onClick={() => handleNavigation("Dashboard")}
              selected={selectedComponent === "Dashboard"}
              style={{
                borderRadius: "8px",
                backgroundColor:
                  selectedComponent === "Dashboard" ? "#6fd943" : "transparent",
                marginBottom: "8px",
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                primary="الملف الشخصي"
                style={{
                  textAlign: "right",
                  fontFamily: "Cairo, sans-serif",
                  margin: 0,
                }}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => handleNavigation("Profile")}
              selected={selectedComponent === "Profile"}
              style={{
                borderRadius: "8px",
                backgroundColor:
                  selectedComponent === "Profile" ? "#6fd943" : "transparent",
                marginBottom: "8px",
              }}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText
                primary="الحضور"
                style={{
                  textAlign: "right",
                  fontFamily: "Cairo, sans-serif",
                  margin: 0,
                }}
              />
            </ListItem>
            <ListItem button onClick={handleClick}>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText
                primary="المهام"
                style={{ textAlign: "right", fontFamily: "Cairo, sans-serif" }}
              />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  onClick={() => handleNavigation("TaskList")}
                  selected={selectedComponent === "TaskList"}
                  style={{
                    borderRadius: "8px",
                    backgroundColor:
                      selectedComponent === "TaskList"
                        ? "#6fd943"
                        : "transparent",
                    marginBottom: "8px",
                    paddingLeft: 32,
                  }}
                >
                  <ListItemIcon>
                    <TableChartIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="قائمة المهام"
                    style={{
                      textAlign: "right",
                      fontFamily: "Cairo, sans-serif",
                      margin: 0,
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => handleNavigation("TaskView")}
                  selected={selectedComponent === "TaskView"}
                  style={{
                    borderRadius: "8px",
                    backgroundColor:
                      selectedComponent === "TaskView"
                        ? "#6fd943"
                        : "transparent",
                    marginBottom: "8px",
                    paddingLeft: 32,
                  }}
                >
                  <ListItemIcon>
                    <ViewListIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="عرض المهام"
                    style={{
                      textAlign: "right",
                      fontFamily: "Cairo, sans-serif",
                      margin: 0,
                    }}
                  />
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              button
              onClick={() => handleNavigation("Employees")}
              selected={selectedComponent === "Employees"}
              style={{
                borderRadius: "8px",
                backgroundColor:
                  selectedComponent === "Employees" ? "#6fd943" : "transparent",
                marginBottom: "8px",
              }}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText
                primary="الموظفين"
                style={{
                  textAlign: "right",
                  fontFamily: "Cairo, sans-serif",
                  margin: 0,
                }}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => handleNavigation("PermissionsAndLeaves")}
              selected={selectedComponent === "PermissionsAndLeaves"}
              style={{
                borderRadius: "8px",
                backgroundColor:
                  selectedComponent === "PermissionsAndLeaves"
                    ? "#6fd943"
                    : "transparent",
                marginBottom: "8px",
              }}
            >
              <ListItemIcon>
                {/* Use Badge here */}
                <Badge badgeContent={notificationCount} color="error">
                  <PersonIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText
                primary="الأذونات والإجازات"
                style={{
                  textAlign: "right",
                  fontFamily: "Cairo, sans-serif",
                  margin: 0,
                }}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => handleNavigation("MeetingTimes")}
              selected={selectedComponent === "MeetingTimes"}
              style={{
                borderRadius: "8px",
                backgroundColor:
                  selectedComponent === "MeetingTimes"
                    ? "#6fd943"
                    : "transparent",
                marginBottom: "8px",
              }}
            >
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText
                primary="أوقات الاجتماعات"
                style={{
                  textAlign: "right",
                  fontFamily: "Cairo, sans-serif",
                  margin: 0,
                }}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => handleNavigation("Tickets")}
              selected={selectedComponent === "Tickets"}
              style={{
                borderRadius: "8px",
                backgroundColor:
                  selectedComponent === "Tickets" ? "#6fd943" : "transparent",
                marginBottom: "8px",
              }}
            >
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText
                primary="التذاكر"
                style={{
                  textAlign: "right",
                  fontFamily: "Cairo, sans-serif",
                  margin: 0,
                }}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => handleNavigation("OfficialHolidays")}
              selected={selectedComponent === "OfficialHolidays"}
              style={{
                borderRadius: "8px",
                backgroundColor:
                  selectedComponent === "OfficialHolidays"
                    ? "#6fd943"
                    : "transparent",
                marginBottom: "8px",
              }}
            >
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText
                primary="الإجازات الرسمية"
                style={{
                  textAlign: "right",
                  fontFamily: "Cairo, sans-serif",
                  margin: 0,
                }}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => handleNavigation("Complaints")}
              selected={selectedComponent === "Complaints"}
              style={{
                borderRadius: "8px",
                backgroundColor:
                  selectedComponent === "Complaints"
                    ? "#6fd943"
                    : "transparent",
                marginBottom: "8px",
              }}
            >
              <ListItemIcon>
                <ComplaintIcon />
              </ListItemIcon>
              <ListItemText
                primary="الشكاوي"
                style={{
                  textAlign: "right",
                  fontFamily: "Cairo, sans-serif",
                  margin: 0,
                }}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => handleNavigation("Settings")}
              selected={selectedComponent === "Settings"}
              style={{
                borderRadius: "8px",
                backgroundColor:
                  selectedComponent === "Settings" ? "#6fd943" : "transparent",
                marginBottom: "8px",
              }}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                primary="الإعدادات"
                style={{
                  textAlign: "right",
                  fontFamily: "Cairo, sans-serif",
                  margin: 0,
                }}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => handleNavigation("TeamLeaderSkills")}
              selected={selectedComponent === "TeamLeaderSkills"}
              style={{
                borderRadius: "8px",
                backgroundColor:
                  selectedComponent === "TeamLeaderSkills"
                    ? "#6fd943"
                    : "transparent",
                marginBottom: "8px",
              }}
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText
                primary="مهارات الموظفين"
                style={{
                  textAlign: "right",
                  fontFamily: "Cairo, sans-serif",
                  margin: 0,
                }}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => handleNavigation("ProfessionalPosts")}
              selected={selectedComponent === "ProfessionalPosts"}
              style={{
                borderRadius: "8px",
                backgroundColor:
                  selectedComponent === "ProfessionalPosts"
                    ? "#6fd943"
                    : "transparent",
                marginBottom: "8px",
              }}
            >
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText
                primary="المنشورات المهنية"
                style={{
                  textAlign: "right",
                  fontFamily: "Cairo, sans-serif",
                  margin: 0,
                }}
              />
            </ListItem>
            <ListItem
              button
              onClick={handleLogout} // Call handleLogout on click
              selected={selectedComponent === "Logout"}
              style={{
                borderRadius: "8px",
                backgroundColor:
                  selectedComponent === "Logout" ? "#6fd943" : "transparent",
                marginBottom: "8px",
              }}
            >
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText
                primary="تسجيل الخروج"
                style={{
                  textAlign: "right",
                  fontFamily: "Cairo, sans-serif",
                  margin: 0,
                }}
              />
            </ListItem>
          </List>
        </DrawerStyled>
      </Root>
    </ThemeProvider>
  );
};

export default UserDashboardSidebar;
