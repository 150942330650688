// // import { useEffect, useRef } from 'react';
// // import axios from 'axios';

// // const useDailyAPI = () => {
// //   const intervalIdRef = useRef(null); // تخزين الـ Interval ID باستخدام useRef

// //   useEffect(() => {
// //     console.log('Setting up timers...');

// //     const handleDailyAPICall = () => {
// //       console.log('Calling API...');
// //       axios
// //         .post('https://backedndforelbatt.elbatt.com/add_BlockedDay_toLeaveRequests.php')
// //         .then((response) => {
// //           if (response.data.success) {
// //             console.log('API called successfully:', response.data);
// //           } else {
// //             console.error('API error:', response.data.message);
// //           }
// //         })
// //         .catch((error) => {
// //           console.error('Error calling the API:', error);
// //         });
// //     };

// //     const now = new Date();
// //     const nextRun = new Date();
// //     nextRun.setHours(11, 3, 0, 0); // تحديد وقت التشغيل القادم (11:03 صباحًا)

// //     // إذا كان الوقت الحالي قد تجاوز 11:03 صباحًا، نحدد التشغيل القادم لليوم التالي
// //     if (now > nextRun) {
// //       nextRun.setDate(nextRun.getDate() + 1);
// //     }

// //     const delay = nextRun - now; // حساب الوقت المتبقي حتى التشغيل القادم
// //     console.log('Next API call scheduled in:', delay, 'ms');

// //     // إنشاء مؤقت (Timer) للاستدعاء الأول
// //     const timerId = setTimeout(() => {
// //       console.log('First API call executed.');
// //       handleDailyAPICall(); // استدعاء الـ API للمرة الأولى
// //       intervalIdRef.current = setInterval(handleDailyAPICall, 24 * 60 * 60 * 1000); // إنشاء فاصل زمني (Interval) يومي
// //       console.log('Interval set for daily API calls.');
// //     }, delay);

// //     // تنظيف المؤقتات والفواصل الزمنية عند إلغاء تحميل الكومبوننت
// //     return () => {
// //       console.log('Cleaning up timers and intervals...');
// //       clearTimeout(timerId); // تنظيف المؤقت (Timer)
// //       if (intervalIdRef.current) {
// //         clearInterval(intervalIdRef.current); // تنظيف الفاصل الزمني (Interval)
// //       }
// //     };
// //   }, []); // التأكد من أن الـ useEffect يعمل مرة واحدة فقط
// // };

// // export default useDailyAPI;


// import { useEffect, useRef } from 'react';
// import axios from 'axios';

// const useDailyAPI = () => {
//   const timerIdRef = useRef(null); // تخزين الـ Timer ID باستخدام useRef

//   useEffect(() => {
//     console.log('Setting up timer...');

//     const handleDailyAPICall = () => {
//       console.log('Calling API...');
//       axios
//         .post('https://backedndforelbatt.elbatt.com/add_BlockedDay_toLeaveRequests.php')
//         .then((response) => {
//           if (response.data.success) {
//             console.log('API called successfully:', response.data);
//           } else {
//             console.error('API error:', response.data.message);
//           }
//         })
//         .catch((error) => {
//           console.error('Error calling the API:', error);
//         });
//     };

//     const now = new Date();
//     const nextRun = new Date();
//     nextRun.setHours(9, 0 , 0, 0); // تحديد وقت التشغيل القادم (9:00 صباحًا)

//     // إذا كان الوقت الحالي قد تجاوز 9:00 صباحًا، نحدد التشغيل القادم لليوم التالي
//     if (now > nextRun) {
//       nextRun.setDate(nextRun.getDate() + 1);
//     }

//     const delay = nextRun - now; // حساب الوقت المتبقي حتى التشغيل القادم
//     console.log('Next API call scheduled in:', delay, 'ms');

//     // إنشاء مؤقت (Timer) لاستدعاء الـ API مرة واحدة
//     timerIdRef.current = setTimeout(() => {
//       console.log('API call executed.');
//       handleDailyAPICall(); // استدعاء الـ API مرة واحدة
//     }, delay);

//     // تنظيف المؤقت عند إلغاء تحميل الكومبوننت
//     return () => {
//       console.log('Cleaning up timer...');
//       if (timerIdRef.current) {
//         clearTimeout(timerIdRef.current); // تنظيف المؤقت (Timer)
//       }
//     };
//   }, []); // التأكد من أن الـ useEffect يعمل مرة واحدة فقط
// };

// export default useDailyAPI;

import { useEffect, useRef } from 'react';
import axios from 'axios';

const useDailyAPI = () => {
  const timerIdRef = useRef(null); // تخزين الـ Timer ID باستخدام useRef

  useEffect(() => {
    console.log('Setting up timer...');

    const handleDailyAPICall = () => {
      console.log('Calling API...');
      axios
        .post('https://backedndforelbatt.elbatt.com/add_BlockedDay_toLeaveRequests.php')
        .then((response) => {
          if (response.data.success) {
            console.log('API called successfully:', response.data);
          } else {
            console.error('API error:', response.data.message);
          }
        })
        .catch((error) => {
          console.error('Error calling the API:', error);
        })
        .finally(() => {
          // بعد الانتهاء من استدعاء الـ API، نعيد جدولة الاستدعاء لليوم التالي
          scheduleNextAPICall();
        });
    };

    const scheduleNextAPICall = () => {
      const now = new Date();
      const nextRun = new Date();
      nextRun.setHours(9, 0, 0, 0); // تحديد وقت التشغيل القادم (9:00 صباحًا)

      // إذا كان الوقت الحالي بعد 9:00 صباحًا، نحدد التشغيل لليوم التالي
      if (now > nextRun) {
        nextRun.setDate(nextRun.getDate() + 1);
      }

      const delay = nextRun - now; // حساب الوقت المتبقي حتى التشغيل القادم
      console.log('Next API call scheduled in:', delay, 'ms');

      // إنشاء مؤقت (Timer) لاستدعاء الـ API
      timerIdRef.current = setTimeout(() => {
        console.log('API call executed.');
        handleDailyAPICall(); // استدعاء الـ API
      }, delay);
    };

    // جدولة الاستدعاء الأول
    scheduleNextAPICall();

    // تنظيف المؤقت عند إلغاء تحميل الكومبوننت
    return () => {
      console.log('Cleaning up timer...');
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current); // تنظيف المؤقت (Timer)
      }
    };
  }, []); // التأكد من أن الـ useEffect يعمل مرة واحدة فقط
};

export default useDailyAPI;